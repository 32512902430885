<template>
  <ValidationObserver ref="observe">
    <b-form>
      <!--form add package-->
      <ek-dialog
        ref="addDialog"
        title="إضافة حزمة "
        size="md"
        placeholder="ابحث عن حزمة محدد"
        btnText=" حزمة جديدة"
        @ok="submit()"
        @close="reset"
        @search="setSearch"
      >
        <template #body>
          <ek-input-text
            :rules="[{ type: 'required', message: 'اسم الحزمة مطلوب' }]"
            name="name"
            placeholder="أدخل اسم الحزمة"
            label="اسم الحزمة  "
            v-model="packageDto.name"
          ></ek-input-text>

          <b-form-group label="نوع الحزمة" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              :options="packageOptions.map((el) => ({ value: el.id, text: el.name }))"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
              v-model="packageDto.packageType"
            >
            </b-form-radio-group>
          </b-form-group>

          <ek-input-select
            name="الفرع"
            label=" تابع ل جامعة - كلية - فرع "
            placeholder="جامعة - كلية - فرع"
            :options="departmentsSubjectsOptions"
            v-model="packageDto.departmentsIds"
          >
          </ek-input-select>
          <ek-input-select
            v-if="packageDto.departmentsIds"
            name="المادة"
            v-model="packageDto.subjectIds"
            multiple
            label=" تابع ل سنة - فصل - مادة "
            placeholder="تابع ل سنة - فصل - مادة "
            :options="
              departmentsSubjectsOptions.find((d) => d.id == packageDto.departmentsIds)
                .subjects
            "
          >
          </ek-input-select>
          <ek-input-select
            v-if="packageDto.packageType == 'Course' && packageDto.subjectIds"
            name="كورسات الحزمة"
            :options="filteredCourses"
            v-model="packageDto.courseIds"
            multiple
            label="  كورسات الحزمة "
            placeholder="حدد كورسات الحزمة"
          >
          </ek-input-select>

          <ek-input-textarea
            name="description"
            placeholder="ادخل  وصف الحزمة "
            label=" وصف الحزمة  "
            v-model="packageDto.description"
          ></ek-input-textarea>
          <ek-input-text
            v-if="packageDto.subjectIds.length && packageDto.packageType == 'Subject'"
            :rules="[{ type: 'required', message: ' كلفة الحزمة  مطلوبة' }]"
            name="price"
            placeholder="ادخل  كلفة الحزمة  "
            label=" كلفة الحزمة  "
            :value="totalCount"
            @input="setCutomPrice"
          ></ek-input-text>

          <ek-input-text
            v-if="packageDto.subjectIds.length && packageDto.packageType == 'Course'"
            :rules="[{ type: 'required', message: ' كلفة الحزمة  مطلوبة' }]"
            name="price"
            placeholder="ادخل  كلفة الحزمة  "
            label=" كلفة الحزمة  "
            type="number"
            v-model="packageDto.price"
          ></ek-input-text>
        </template>
      </ek-dialog>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ValidationObserver,
  },
  data: () => ({
    customPrice: "",
  }),
  computed: {
    ...mapState({
      packageDto: (state) => state.codes.packageDto,
      packageOptions: (state) => state.codes.packageOptions,
      departmentsSubjectsOptions: ({ courses }) => courses.departmentsSubjectsOptions,
      universityNameCourse: ({ university }) => university.universityNameCourse,
      courses: ({ courses }) => courses.baseCoursesList,
      filteredCourses: ({ codes, courses }) =>
        courses.baseCoursesList.filter((co) =>
          codes.packageDto.subjectIds.includes(co.subjectId)
        ),
      totalCount: ({ courses, codes }) => {
        const selectedDpartment = courses.departmentsSubjectsOptions.find(
          (el) => el.id == codes.packageDto.departmentsIds
        );
        if (selectedDpartment) {
          console.log(selectedDpartment);
          const subjects = selectedDpartment.subjects;

          return subjects
            .filter((s) => codes.packageDto.subjectIds.some((id) => id == s.id))
            .reduce((current, next) => {
              return current + next.price;
            }, 0);
        } else return 0;
      },
    }),
  },
  methods: {
    ...mapActions(["addPackage", "getDepartmentsWithSubjects", "getBaseCoursesList"]),
    setCutomPrice(e) {
      this.customPrice = e;
    },
    //submit add packages
    submit() {
      this.$refs.observe.validate().then((success) => {
        if (success && this.packageDto.packageType == "Subject") {
          console.log(this.customPrice, "CUSTOM");
          this.addPackage({
            ...this.packageDto,
            price: this.customPrice ? this.customPrice : this.totalCount,
          });
          this.$refs.addDialog.close();
          this.$refs.observe.reset();
        } else {
          this.addPackage(this.packageDto);
          this.$refs.addDialog.close();
          this.$refs.observe.reset();
        }
      });
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "price", "isHidden"],
        query,
      });
    },
    reset() {
      this.$refs["observe"].reset();
      Object.assign(this.packageDto, {
        name: "",
        price: "",
        description: "",
        subjectIds: [],
      });
    },
  },
  created() {
    this.getDepartmentsWithSubjects();
    this.getBaseCoursesList();
  },
};
</script>
